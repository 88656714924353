var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"position-relative"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"container-fluid content px-0",staticStyle:{"height":"calc(100vh - 5px)"}},[_c('div',{staticClass:"row mx-0",staticStyle:{"height":"calc(100% - 43px)"}},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"row mx-0 mt-5"},[_c('div',{staticClass:"col"},[_c('vueper-slides',{staticClass:"no-shadow text-muted2 h-vueper",attrs:{"touchable":false,"autoplay":"","arrows":false},scopedSlots:_vm._u([{key:"bullet",fn:function(ref){
var active = ref.active;
return [_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-auto px-0"},[_c('i',{staticClass:"icon-brightness-1 ",class:active ? 'text-leeche' : 'text-muted'})])])]}}])},_vm._l((_vm.slides),function(slide){return _c('vueper-slide',{key:slide.id,scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"row mx-0 justify-center"},[_c('div',{staticClass:"col-auto text-center"},[_c('div',{staticClass:"br-12 d-middle-center shadow border py-2 content-slider",staticStyle:{"height":"82px","width":"552px"}},[_vm._v(" "+_vm._s(slide.text)+" ")])])])]},proxy:true}],null,true)})}),1)],1)]),_c('div',{staticClass:"row mx-0 my-4 justify-center postulacion-img"},[_c('div',{staticClass:"position-relative  col px-0",class:_vm.estado_validacion ? 'text-right' : 'text-center'},[_c('img',{staticClass:"obj-cover img-validate",staticStyle:{"max-width":"100%"},attrs:{"src":("/img/auth/" + (_vm.estado_validacion ? 'tendero_registrado' : 'tendero') + ".svg")}})]),(_vm.estado_validacion)?_c('div',{staticClass:"col"},[_vm._m(2)]):_vm._e()]),_c('div',{staticClass:"row mx-0 justify-center"},[_c('div',{staticClass:"col pr-0"},[_c('div',{staticClass:"row mx-0 justify-center"},[_c('router-link',{attrs:{"to":{name: 'login.leechero.terminos-condiciones'}}},[_c('div',{staticClass:"col-auto f-16 text-muted2 f-600"},[_c('i',{staticClass:"icon-book-open-variant"}),_vm._v(" Términos y condiciones ")])]),_c('router-link',{attrs:{"to":{name: 'login.leechero.politica-privacidad'}}},[_c('div',{staticClass:"col-auto f-16 text-muted2 f-600"},[_c('i',{staticClass:"icon-lock"}),_vm._v(" Política de privacidad ")])]),_c('router-link',{attrs:{"to":{name: 'login.leechero.preguntas-frecuentes'}}},[_c('div',{staticClass:"col-auto f-16 text-muted2 f-600"},[_c('i',{staticClass:"icon-help"}),_vm._v(" Preguntas frecuentes ")])])],1)])])]),_c('div',{staticClass:"col-auto"},[_c('ValidationObserver',{ref:"validador",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.cargando.postulacion),expression:"cargando.postulacion"}],staticClass:"row justify-center",attrs:{"id":"postulacion","element-loading-text":"Cargando la información de la tienda, por favor espere..."}},[_c('div',{staticClass:"box border input-pr br-12 bg-white custom-scroll overflow-auto"},[_c('div',{staticClass:"row mx-0 justify-center mt-3"},[_c('div',{staticClass:"col-auto text-center"},[_c('img',{staticClass:"obj-cover",staticStyle:{"max-width":"200px","max-height":"50px"},attrs:{"src":"/img/auth/logo_rojo.svg"}}),_c('p',{staticClass:"f-600 mt-3 text-general"},[_vm._v(" Queremos saber de ti ")])])]),_c('div',{staticClass:"custom-scroll2 my-2"},[_c('div',{staticClass:"row mx-0 mx-5 pt-3 text-muted2 f-15"},[_c('div',{staticClass:"col position-relative"},[_c('p',{staticClass:"text-center text-general my-2 f-500"},[_vm._v("Foto")]),_c('div',{staticClass:"row mx-0 px-1 justify-center"},[_c('slim-cropper',{ref:"cropLogo",staticClass:"border cr-pointer br-12",staticStyle:{"width":"200px","background":"#F8F9FF"},attrs:{"options":_vm.configuracionCropper}},[_c('div',{attrs:{"slot":"label"},slot:"label"},[_c('img',{attrs:{"src":"/img/modales/camera.svg"}})])])],1)])]),_c('div',{staticClass:"row mx-0 justify-center pt-3"},[_c('div',{staticClass:"col-10",staticStyle:{"width":"337px"}},[_c('div',{staticClass:"row mx-3 text-general f-15"},[_vm._v(" ¿Cómo te llamas? ")]),_c('ValidationProvider',{attrs:{"rules":"required","vid":"propietario_nombre","name":"nombre del dueño"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Nombre y apellido","maxlength":"60","show-word-limit":""},model:{value:(_vm.model.propietario_nombre),callback:function ($$v) {_vm.$set(_vm.model, "propietario_nombre", $$v)},expression:"model.propietario_nombre"}}),_c('span',{staticClass:"text-danger f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)]),_c('div',{staticClass:"row mx-0 justify-center pt-3"},[_c('div',{staticClass:"col-10"},[_c('div',{staticClass:"f-15 mx-3 text-general row"},[_vm._v(" ¿Cuándo naciste? ")]),_c('el-date-picker',{staticClass:"w-100",attrs:{"type":"date","format":"dd - MM - yyyy","value-format":"yyyy-MM-dd","picker-options":_vm.pickerOptions},model:{value:(_vm.model.propietario_nacimiento),callback:function ($$v) {_vm.$set(_vm.model, "propietario_nacimiento", $$v)},expression:"model.propietario_nacimiento"}})],1)]),_c('div',{staticClass:"row mx-0 justify-center pt-3"},[_c('div',{staticClass:"col-10 d-middle-center"},[_c('el-radio-group',{model:{value:(_vm.model.propietario_genero),callback:function ($$v) {_vm.$set(_vm.model, "propietario_genero", $$v)},expression:"model.propietario_genero"}},[_c('el-radio',{staticClass:"radio-red",attrs:{"label":2}},[_vm._v(" Mujer ")]),_c('el-radio',{staticClass:"radio-red",attrs:{"label":1}},[_vm._v(" Hombre ")]),_c('el-radio',{staticClass:"radio-red",attrs:{"label":3}},[_vm._v(" Otro ")])],1)],1)]),_c('div',{staticClass:"row mx-0 justify-center pt-3"},[_c('div',{staticClass:"col-10 f-16 my-2 text-general"},[_vm._v(" ¿Dónde estás ubicado? ")]),_c('div',{staticClass:"col-10 position-relative",staticStyle:{"width":"337px"}},[_c('div',{staticClass:"row mx-3 text-general f-15"},[_vm._v(" País ")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"país"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticClass:"w-100",attrs:{"disabled":!_vm.existeMasDeUnPais,"placeholder":"Seleccione un país","filterable":""},model:{value:(_vm.proyecto.seleccion.pais),callback:function ($$v) {_vm.$set(_vm.proyecto.seleccion, "pais", $$v)},expression:"proyecto.seleccion.pais"}},_vm._l((_vm.proyecto.paises),function(item,i){return _c('el-option',{key:i,attrs:{"label":item.nombre,"value":item.id}})}),1),_c('span',{staticClass:"text-danger f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)]),_c('div',{staticClass:"row mx-0 justify-center pt-3"},[_c('div',{staticClass:"col-10 position-relative",staticStyle:{"width":"337px"}},[_c('div',{staticClass:"row mx-3 text-general text-general f-15"},[_vm._v(" Estado ")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"estado"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticClass:"w-100",attrs:{"disabled":!_vm.existeMasDeUnEstado,"placeholder":"Seleccione un estado","filterable":""},model:{value:(_vm.proyecto.seleccion.estado),callback:function ($$v) {_vm.$set(_vm.proyecto.seleccion, "estado", $$v)},expression:"proyecto.seleccion.estado"}},_vm._l((_vm.proyecto.estados),function(item,i){return _c('el-option',{key:i,attrs:{"label":item.nombre,"value":item.id}})}),1),_c('span',{staticClass:"text-danger f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)]),_c('div',{staticClass:"row mx-0 justify-center pt-3"},[_c('div',{staticClass:"col-10 position-relative",staticStyle:{"width":"337px"}},[_c('div',{staticClass:"row mx-3 text-general f-15"},[_vm._v(" Ciudad ")]),_c('ValidationProvider',{attrs:{"rules":"required","vid":"idm_ciudad","name":"ciudad"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticClass:"w-100",attrs:{"disabled":!_vm.existeMasDeUnaCiudad,"placeholder":"Seleccione una ciudad","filterable":""},model:{value:(_vm.model.idm_ciudad),callback:function ($$v) {_vm.$set(_vm.model, "idm_ciudad", $$v)},expression:"model.idm_ciudad"}},_vm._l((_vm.proyecto.ciudades),function(item,i){return _c('el-option',{key:i,attrs:{"label":item.nombre,"value":item.id}})}),1),_c('span',{staticClass:"text-danger f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)]),_c('div',{staticClass:"row mx-0 justify-center pt-3"},[_c('div',{staticClass:"col-10 position-relative",staticStyle:{"width":"337px"}},[_c('div',{staticClass:"row mx-3 text-general f-15"},[_vm._v(" Dirección ")]),_c('ValidationProvider',{attrs:{"rules":"required","vid":"direccion","name":"dirección"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Cra. 80 #40-90","maxlength":"70","show-word-limit":""},on:{"change":_vm.buscarDireccion},model:{value:(_vm.model.direccion),callback:function ($$v) {_vm.$set(_vm.model, "direccion", $$v)},expression:"model.direccion"}}),_c('span',{staticClass:"text-danger f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)]),_c('div',{staticClass:"row mx-0 justify-center pt-3"},[_c('div',{staticClass:"col-10 position-relative",staticStyle:{"width":"337px"}},[_c('div',{staticClass:"row mx-3 text-general f-15"},[_vm._v(" Complemento (opcional) ")]),_c('ValidationProvider',{attrs:{"vid":"direccion_anexo","name":"complemento"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Local #123","maxlength":"40","show-word-limit":""},on:{"change":_vm.buscarDireccion},model:{value:(_vm.model.direccion_anexo),callback:function ($$v) {_vm.$set(_vm.model, "direccion_anexo", $$v)},expression:"model.direccion_anexo"}}),_c('span',{staticClass:"text-danger f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)]),_c('div',{staticClass:"row mx-0 justify-center pt-3"},[_c('div',{staticClass:"col-10 position-relative",staticStyle:{"width":"337px"}},[_c('div',{staticClass:"row mx-3 text-general f-15"},[_vm._v(" Barrio / Colonia ")]),_c('ValidationProvider',{attrs:{"vid":"barrio","rules":"required|max:30","name":"barrio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"¿En qué barrio se ubica?","maxlength":"30","show-word-limit":""},model:{value:(_vm.model.barrio),callback:function ($$v) {_vm.$set(_vm.model, "barrio", $$v)},expression:"model.barrio"}}),_c('span',{staticClass:"text-danger f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)]),_c('div',{staticClass:"row mx-0 justify-center pt-3"},[_c('div',{staticClass:"col-10 px-0 position-relative my-3 cr-pointer"},[_c('div',{staticClass:"position-absolute h-100 w-100",staticStyle:{"left":"0px","top":"0px","z-index":"3"},on:{"click":function($event){return _vm.abrirModalMapa()}}}),_c('mapa',{ref:"mapaPeq",attrs:{"coordenadas":_vm.coordenadas,"buscar":_vm.getDireccionCon},on:{"actualizar":_vm.actualizarCoordenadas}}),_c('p',{staticClass:"f-13 mt-2 text-gris2"},[_vm._v(" Haga click aquí, para seleccionar la ubicación de su tienda ")])],1)]),(_vm.login == 2)?_c('div',{staticClass:"row mx-0 justify-center pt-3"},[_c('div',{staticClass:"col-10 my-2 text-general"},[_vm._v(" ¿Cómo te podemos contactar? ")]),_c('div',{staticClass:"col-10",staticStyle:{"width":"337px"}},[_c('div',{staticClass:"row mx-3 text-general f-15"},[_vm._v(" Número telefono ")]),_c('ValidationProvider',{attrs:{"rules":_vm.reglas.telefono(true),"vid":"telefono","name":"teléfono"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"type":"tel","placeholder":"301234567","maxlength":"18","show-word-limit":""},model:{value:(_vm.model.telefono),callback:function ($$v) {_vm.$set(_vm.model, "telefono", $$v)},expression:"model.telefono"}},[_c('template',{slot:"prepend"},[_c('img',{staticClass:"rounded-circle",attrs:{"src":_vm.getBandera,"alt":"","width":"25","height":"25"}}),_c('span',{staticClass:"text-danger mx-1"},[_vm._v("+"+_vm._s(_vm.getPrefijo))])])],2),_c('span',{staticClass:"text-danger f-11"},[_vm._v(" "+_vm._s(errors[0] || _vm.errorUsuarioTomado.telefono)+" ")])]}}],null,true)})],1)]):_vm._e(),(_vm.login == 1)?_c('div',{staticClass:"row mx-0 justify-center pt-3"},[_c('div',{staticClass:"col-10",staticStyle:{"width":"337px"}},[_c('div',{staticClass:"row mx-3 text-general f-15"},[_vm._v(" Correo electrónico ")]),_c('ValidationProvider',{attrs:{"rules":"required|email","vid":"correo","name":"correo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"type":"email","placeholder":"ejemplo@correo.com","maxlength":"70","show-word-limit":""},model:{value:(_vm.model.correo),callback:function ($$v) {_vm.$set(_vm.model, "correo", $$v)},expression:"model.correo"}},[(errors[0] || _vm.errorUsuarioTomado.correo)?_c('i',{staticClass:"text-red icon-info-circled-alt f-18 p-2 d-flex align-items-center",attrs:{"slot":"suffix"},slot:"suffix"}):_vm._e()]),_c('span',{staticClass:"text-danger f-11"},[_vm._v(" "+_vm._s(errors[0] || _vm.errorUsuarioTomado.correo)+" ")])]}}],null,true)})],1)]):_vm._e(),_c('div',{staticClass:"row mx-0 justify-center pt-3"},[_c('div',{staticClass:"col-10 position-relative",staticStyle:{"width":"337px"}},[_c('div',{staticClass:"row mx-3 text-general f-15"},[_vm._v(" Cúentanos más de ti ")]),_c('ValidationProvider',{attrs:{"rules":"required","vid":"descripcion","name":"descripción"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"type":"textarea","rows":"4","placeholder":"Describa a qué se dedica su tienda","maxlength":"250","show-word-limit":""},model:{value:(_vm.model.descripcion),callback:function ($$v) {_vm.$set(_vm.model, "descripcion", $$v)},expression:"model.descripcion"}}),_c('span',{staticClass:"text-danger f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)]),_c('div',{staticClass:"row mx-0 pt-3 text-muted2 f-15 justify-center"},[_c('div',{staticClass:"col-10 d-middle my-3"},[_c('el-checkbox',{staticClass:"check-red",attrs:{"label":"1"},model:{value:(_vm.tienda),callback:function ($$v) {_vm.tienda=$$v},expression:"tienda"}},[_vm._v(" Tengo una tienda ")])],1)]),(_vm.tienda)?[_c('div',[_c('div',{staticClass:"row mx-0 justify-center pt-3"},[_c('div',{staticClass:"col-10",staticStyle:{"width":"337px"}},[_c('div',{staticClass:"row mx-3 text-general f-15"},[_vm._v(" Nombre de la tienda ")]),_c('ValidationProvider',{attrs:{"rules":"required","vid":"nombre","name":"nombre de la tienda"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"¿Cómo se llama tu tienda?","maxlength":"60","show-word-limit":""},model:{value:(_vm.model.nombre),callback:function ($$v) {_vm.$set(_vm.model, "nombre", $$v)},expression:"model.nombre"}}),_c('span',{staticClass:"text-danger f-11"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row mx-0 justify-center pt-3"},[_c('div',{staticClass:"col-10 position-relative",staticStyle:{"width":"337px"}},[_c('div',{staticClass:"row mx-3 text-general f-15"},[_vm._v(" Número de identificación fiscal (Opcional) ")]),_c('ValidationProvider',{attrs:{"rules":"numeric","vid":"nit","name":"número de identificación fiscal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"123456789","maxlength":"10","show-word-limit":""},model:{value:(_vm.model.nit),callback:function ($$v) {_vm.$set(_vm.model, "nit", _vm._n($$v))},expression:"model.nit"}}),_c('span',{staticClass:"text-danger f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)]),_c('div',{staticClass:"row mx-0 justify-center pt-3"},[_c('div',{staticClass:"col-10 position-relative",staticStyle:{"width":"337px"}},[_c('div',{staticClass:"row mx-3 text-general f-15"},[_vm._v(" Soporte de la identificación fiscal (Opcional) ")]),_c('el-upload',{ref:"archivo",staticClass:"upload-demo w-100",attrs:{"auto-upload":false,"multiple":false,"on-change":_vm.capturarSubidaArchivo,"accept":"image/*,application/pdf","action":"#"}},[_c('button',{staticClass:"btn btn-cancelar bg-whitesmoke2 w-100 br-10",attrs:{"type":"button"}},[_vm._v(" Cargar Soporte ")])]),_c('p',{staticClass:"text-muted f-12 mt-2"},[_vm._v("Sólo archivos PDF e imágenes")])],1)])])]:_vm._e(),_c('div',{staticClass:"row mx-0 pt-3 text-muted2 f-15 justify-center"},[_c('div',{staticClass:"mt-2"},[_c('p',{staticClass:"pl-3 text-center text-general"},[_vm._v("¿Hay algo que nos quieras mostrar?")])])]),_c('div',{staticClass:"row mx-0 pt-3 text-muted2 f-15 justify-center "},[_c('div',{staticClass:"col pr-2"},[_c('p',{staticClass:"text-center mb-2"},[_c('i',{staticClass:"f-10 pl-3"},[_vm._v("Tamaño recomendado 140 * 140 ")])]),_c('div',{staticClass:"row mx-0 justify-center"},[_c('div',{staticClass:"col text-center"},[_c('el-upload',{staticClass:"row justify-center mx-0 upload-login",attrs:{"action":"#","auto-upload":false,"before-upload":_vm.validarImagenCargada,"on-change":_vm.capturarSubidaImagenes,"list-type":"picture-card","file-list":_vm.adjuntosImagenes,"accept":"image/*","multiple":true,"limit":4},scopedSlots:_vm._u([{key:"file",fn:function(ref){
var file = ref.file;
return _c('div',{},[_c('span',{staticClass:"el-upload-list__item-actions"},[_c('span',{staticClass:"el-upload-list__item-delete",on:{"click":function($event){return _vm.handleRemove(file)}}},[_c('i',{staticClass:"el-icon-delete"})])]),_c('img',{staticClass:"el-upload-list__item-thumbnail",attrs:{"src":file.url,"alt":""}})])}}],null,true)},[_c('img',{attrs:{"slot":"default","src":"/img/icons/add-image.svg"},slot:"default"})])],1)])])]),_c('div',{staticClass:"row mx-0 justify-center pt-4"},[_c('div',{staticClass:"col-10 mb-2 f-16 text-general"},[_vm._v(" Crea tu contraseña ")]),_c('div',{staticClass:"col-10 position-relative",staticStyle:{"width":"337px"}},[_c('div',{staticClass:"row mx-3 text-general f-15"},[_vm._v(" Contraseña ")]),_c('ValidationProvider',{attrs:{"rules":_vm.reglas.contrasena,"vid":"password","name":"contraseña"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Escriba una contraseña","maxlength":"200","minlength":"8","show-password":""},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}}),_c('span',{staticClass:"text-danger f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('p',{staticClass:"text-muted f-12 mt-2"},[_vm._v("La contraseña debe tener mínimo 8 cacacteres y contener al menos 1 letra")])]}}],null,true)})],1)]),_c('div',{staticClass:"row mx-0 justify-center pt-3"},[_c('div',{staticClass:"col-10 position-relative",staticStyle:{"width":"337px"}},[_c('div',{staticClass:"row mx-3 text-general f-15"},[_vm._v(" Confirmar contraseña ")]),_c('ValidationProvider',{attrs:{"rules":"required_if:password|confirmed:password","name":"confirmar contraseña"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Repita su contraseña","show-password":""},model:{value:(_vm.validacionContrasena),callback:function ($$v) {_vm.validacionContrasena=$$v},expression:"validacionContrasena"}}),_c('span',{staticClass:"text-danger f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)]),_c('div',{staticClass:"row mx-0 justify-center pt-3"},[_c('div',{staticClass:"col-10 d-middle-center",staticStyle:{"width":"337px"}},[_c('el-checkbox',{staticClass:"check-red",model:{value:(_vm.aceptaTerminosCondiciones),callback:function ($$v) {_vm.aceptaTerminosCondiciones=$$v},expression:"aceptaTerminosCondiciones"}}),_c('router-link',{staticClass:"f-14 ml-2 text-muted2",attrs:{"to":{ name: 'login.terminos-condiciones', params: {} },"target":"_blank"}},[_vm._v(" He leído y acepto los "),_c('span',{staticClass:"text-general"},[_vm._v(" términos y "),_c('br'),_vm._v(" condiciones y Pólitica de privacidad ")])])],1)])],2),_c('div',{staticClass:"row mx-0 mt-3 px-5 justify-center"},[_c('div',{staticClass:"col-auto text-center",staticStyle:{"width":"337px"}},[_c('button',{staticClass:"btn btn-block br-12 p-1 py-2 d-flex d-middle-center text-white bg-leeche border-0",attrs:{"disabled":invalid || !_vm.aceptaTerminosCondiciones},on:{"click":_vm.intentarPostularTienda}},[_vm._v(" Quiero sumarme ")])])]),_c('div',{staticClass:"row mx-0 my-4 justify-center"},[_c('router-link',{staticClass:"text-leeche f-18 f-500",attrs:{"to":"/login"}},[_vm._v(" Ya tengo un usuario ")])],1)])])]}}])})],1),_c('div',{staticClass:"col-auto"}),_c('div',{staticClass:"col-auto"})])]),_c('mapa-google-modal',{ref:"modalMapaGoogle",staticClass:"centrar-modal",attrs:{"postal":"","busqueda":"","buscar":_vm.getDireccionCon,"coordenadas-entrada":_vm.coordenadasCiudad},on:{"actualizar":_vm.actualizarCoordenadasCiudad}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 h-100 position-absolute container-img"},[_c('div',{staticClass:"img-background"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar-postulacion pl-4"},[_c('img',{staticClass:"ml-4 mr-4 obj-cover",staticStyle:{"max-width":"150px","max-height":"80px"},attrs:{"src":"/img/auth/logo_rojo.svg"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shadow position-relative br-12 px-2 py-3 text-center w-100",staticStyle:{"max-width":"280px","z-index":"2"}},[_c('div',{staticClass:"arrow-oh position-absolute"}),_c('p',{staticClass:"text-leeche f-40 f-600"},[_vm._v(" !Oh Oh! ")]),_c('p',{staticClass:"text-muted2 f-18 mt-2"},[_vm._v(" Parece que ya hay un usuario registrado con esos datos "),_c('br'),_vm._v(" Para mayor informacion comunícate con: "),_c('br'),_c('span',{staticClass:"text-red f-16"},[_vm._v(" contacto@leeche.com ")])])])}]

export { render, staticRenderFns }